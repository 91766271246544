<template>
  <v-sheet class="pa-4">
    <div class="d-flex align-center">
      <v-tabs v-model="tab" center-active @change="onChangeTab">
        <v-tab>
          <v-icon class="mr-2">mdi-credit-card-multiple</v-icon>
          {{ $t("dues.dues") }}
        </v-tab>
        <v-tab>
          <v-icon class="mr-2">mdi-history</v-icon>{{ $t("dues.history") }}
        </v-tab>
      </v-tabs>
      <v-spacer />
      <v-select
        v-if="tab === 1"
        v-model="dueFilter"
        :label="$t('dues.master_type')"
        :loading="loadingDue"
        :items="dueList"
        clearable
        item-text="label"
        item-value="key"
        class="select-200"
        hide-details
        outlined
        dense
        @change="getAllHistory"
      />
    </div>

    <v-card flat class="mb-6">
      <v-tabs-items v-model="tab" touchless class="mt-4">
        <v-tab-item>
          <v-data-table
            :headers="tableHeaders"
            :items="dataTable"
            :loading="loading"
            class="elevation-0"
          >
            <template v-slot:[`item.billing_date`]="{ item }">
              {{ item.billing_date | dateFormat }}
            </template>
            <template v-slot:[`item.margin_date`]="{ item }">
              {{ item.margin_date | dateFormat }}
            </template>
            <template v-slot:[`item.nominal`]="{ item }">
              Rp. {{ item.nominal | priceFormat }}
            </template>
            <template v-slot:[`item.paid`]="{ item }">
              Rp.
              {{
                Number(item.paid) === 0
                  ? 0
                  : (Number(item.nominal) - Number(item.paid)) | priceFormat
              }}
            </template>
            <template v-slot:[`item.installments`]="{ item }">
              {{ item.installments ? $t("app.yes") : $t("app.no") }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                small
                depressed
                color="primary"
                @click="openHistoryDialog(item)"
              >
                <v-icon small>mdi-arrow-right</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            :headers="tableHeadersHistory"
            :items="dataTable"
            :loading="loading"
            class="elevation-0"
          >
            <template v-slot:[`item.nominal`]="{ item }">
              Rp. {{ item.nominal | priceFormat }}
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | dateFormat }}
            </template>
            <template v-slot:[`item.reference`]="{ item }">
              {{ item.reference ? item.reference : "-" }}
            </template>
            <template v-slot:[`item.margin_date`]="{ item }">
              {{ item.margin_date | dateFormat }}
            </template>
            <template v-slot:[`item.proof`]="{ item }">
              <v-img
                :src="item.proof"
                width="50"
                @click="showProof(item.proof)"
              ></v-img>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog v-model="historyDialog" width="800">
        <v-card>
          <v-card-title>
            History
            <v-spacer />
            <v-icon @click="historyDialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <div v-if="historyList.length === 0">
              {{ $i18n.t("dues.no_transaction") }}
            </div>
            <v-data-table
              v-else
              :headers="tableHeadersHistory"
              :items="historyList"
              :loading="loadingHistory"
              sort-by="created_at"
              sort-desc
              class="elevation-0"
            >
              <template v-slot:[`item.nominal`]="{ item }">
                Rp. {{ item.nominal | priceFormat }}
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | dateFormat }}
              </template>
              <template v-slot:[`item.reference`]="{ item }">
                {{ item.reference ? item.reference : "-" }}
              </template>
              <template v-slot:[`item.margin_date`]="{ item }">
                {{ item.margin_date | dateFormat }}
              </template>
              <template v-slot:[`item.proof`]="{ item }">
                <v-img
                  :src="item.proof"
                  width="50"
                  @click="showProof(item.proof)"
                ></v-img>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="previewProof" width="400">
        <v-card class="text-center">
          <v-img :src="selectedProof"></v-img>
          <v-btn class="ma-4" text @click="previewProof = false">
            {{ $i18n.t("app.close") }}
          </v-btn>
        </v-card>
      </v-dialog>
    </v-card>
  </v-sheet>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment";

import {
  getDuesTransactionList,
  getDuesTransactionHistory,
  getDuesType
} from "@/api/admin/transactions";
import { priceFormat } from "@/utils/validate";

export default {
  metaInfo: {
    title: i18n.t("routes.dues"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  filters: {
    priceFormat: value => {
      if (!value) return "0";
      return priceFormat(value);
    },
    dateFormat: value => {
      if (!value) return "0";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      loadingDue: false,
      dueList: [],
      dueFilter: 0,
      currentDate: moment().format("YYYY-MM-DD"),
      tab: 0,
      queryData: {
        sort: "created_at",
        order: "DESC"
      },
      tableHeaders: [
        {
          text: i18n.t("dues.table.name"),
          value: "name"
        },
        {
          text: i18n.t("dues.table.pay_date"),
          value: "billing_date"
        },
        {
          text: i18n.t("dues.table.margin_date"),
          value: "margin_date"
        },
        {
          text: i18n.t("dues.table.bill"),
          value: "nominal"
        },
        {
          text: i18n.t("dues.table.unpaid"),
          value: "paid"
        },
        {
          text: i18n.t("dues.table.can_be_repaid"),
          value: "installments"
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 80
        }
      ],
      tableHeadersHistory: [
        { text: i18n.t("dues.table.dues_type"), value: "name" },
        { text: i18n.t("dues.table.paid"), value: "nominal" },
        {
          text: i18n.t("dues.table.payment_date"),
          value: "created_at"
        },
        {
          text: i18n.t("dues.table.margin_date"),
          value: "margin_date"
        },
        {
          text: i18n.t("dues.table.reference"),
          value: "reference"
        },
        {
          text: i18n.t("dues.table.proof"),
          value: "proof",
          sortable: false
        }
      ],
      loadingHistory: false,
      previewProof: false,
      selectedProof: null,
      total: 0,
      length: 0,
      loading: false,
      dataTable: [],
      historyList: [],
      historyDialog: false,
      historySelected: null,
      options: {},
      duesDataDialog: false
    };
  },
  async mounted() {
    await this.getDuesListHandler();
  },
  created() {
    this.getDuesFilter();
  },
  methods: {
    onChangeTab(value) {
      switch (value) {
        case 0:
          this.getDuesListHandler();
          break;
        case 1:
          this.getAllHistory();
          break;
      }
    },
    getAllHistory() {
      this.loading = true;
      getDuesTransactionHistory({
        person: this.$store.getters.g_user.person,
        due: this.dueFilter
      }).then(res => {
        const { data, code } = res.data;
        if (code === 1) this.dataTable = data;
        this.loading = false;
      });
    },
    getDuesFilter() {
      this.loadingDue = true;
      getDuesType({ person: this.$store.getters.g_user.person }).then(res => {
        const { data, code } = res.data;
        if (code === 1) this.dueList = data;
        this.loadingDue = false;
      });
    },
    showProof(proof) {
      this.selectedProof = proof;
      this.previewProof = true;
    },
    openHistoryDialog(item) {
      this.historySelected = item;
      this.historyDialog = true;
      this.loadingHistory = true;
      getDuesTransactionHistory()
        .then(res => {
          if (res.data.code) {
            this.historyList = res.data.data;
          }
          this.loadingHistory = false;
        })
        .catch(() => (this.loadingHistory = false));
    },
    setColor(param) {
      let color = "";
      switch (param) {
        case "NOT_YET_PAID":
          color = "error";
          break;
        case "PAID_OFF":
          color = "success";
          break;
        case "PARTIALLY_PAID":
          color = "warning";
          break;
        default:
          color = "grey";
          break;
      }
      return color;
    },
    getDuesListHandler() {
      this.formPersonTransaction = null;
      this.loading = true;

      getDuesTransactionList({ ...this.queryData })
        .then(res => {
          if (res.data.code) {
            this.dataTable = res.data.data;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    }
  }
};
</script>
