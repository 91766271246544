<template>
  <v-sheet>
    <component :is="currentRole" :g_roles="g_roles" />
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

import DuesAdmin from "./DuesAdmin";
import DuesStudent from "./DuesStudent";

export default {
  name: "Home",
  metaInfo: {
    title: "Dashboard",
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    DuesAdmin,
    DuesStudent
  },
  computed: {
    ...mapGetters(["g_roles"])
  },
  data() {
    return {
      currentRole: "DuesAdmin"
    };
  },
  created() {
    if (this.g_roles.includes(4) || this.g_roles.includes(5)) {
      this.currentRole = "DuesAdmin";
    } else {
      this.currentRole = "DuesStudent";
    }
  }
};
</script>
