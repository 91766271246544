<template>
  <v-sheet class="pa-4">
    <v-row no-gutters class="mb-4">
      <v-text-field
        v-model="queryData.search"
        :label="$t('dues.table.name_or_nis')"
        append-icon="mdi-magnify"
        class="widthMax150 mr-2"
        dense
        outlined
        hide-details
        @keyup.enter="() => ((queryData.page = 1), getDuesListHandler())"
      />

      <v-autocomplete
        v-model="queryData.classroom"
        :disabled="classList.length == 0"
        :label="$t('dues.table.class')"
        :loading="loadingGetClass"
        :items="classList"
        item-value="id"
        item-text="name"
        class="select-200 d-inline-block mr-2"
        outlined
        dense
        hide-details
        clearable
        @change="() => ((queryData.page = 1), getDuesListHandler())"
      />

      <div class="widthMax200">
        <v-menu
          ref="menu"
          v-model="tglLahir"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          head
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="queryData.birth_date"
              :label="$t('master_data.student.upload_excel.dob')"
              append-icon="mdi-calendar"
              readonly
              dense
              outlined
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="
                () => {
                  queryData.birth_date = null;
                  queryDate.page = 1;
                  getDuesListHandler();
                }
              "
            />
          </template>
          <v-date-picker
            v-model="queryData.birth_date"
            :active-picker.sync="activePicker"
            min="1950-01-01"
            @change="getDuesListHandler()"
          />
        </v-menu>

        <!-- <DatePicker
          :label="$t('master_data.student.upload_excel.dob')"
          :rule="[]"
          :value="queryData.birth_date"
          :maxDate="currentDate"
          outlined
          dense
          hide-details
          @getValue="
            v => {
              queryData.birth_date = v;
              getDuesListHandler();
            }
          "
        /> -->
      </div>
      <v-spacer />
      <v-btn
        dark
        depressed
        color="gradient-primary"
        class="mr-2"
        @click="actionHandler('DuesMaster')"
      >
        {{ $t("dues.master") }}
        <v-icon class="ml-2">mdi-settings</v-icon>
      </v-btn>
    </v-row>

    <v-card flat class="mb-6">
      <v-data-table
        :headers="tableHeaders"
        :items="dataTable"
        :page.sync="queryData.page"
        :items-per-page="queryData.limit"
        :loading="loading"
        :options.sync="options"
        hide-default-footer
        disable-pagination
        class="elevation-0"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-row no-gutters align="center" class="py-2">
            <v-avatar class="mr-4">
              <v-img v-if="item.photo" :src="item.photo" />
              <v-img v-else :src="item.gender == 'L' ? boyPhoto : girlPhoto" />
            </v-avatar>
            <div class="text-left">
              <div class="text-truncate font-weight-bold">{{ item.name }}</div>
              <div v-if="item.nis" class="caption">{{ item.nis }}</div>
            </div>
          </v-row>
        </template>
        <template v-slot:[`item.total_nominal`]="{ item }">
          Rp. {{ item.total_nominal | priceFormat }}
        </template>
        <template v-slot:[`item.total_paid`]="{ item }">
          Rp. {{ item.total_paid | priceFormat }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            small
            depressed
            color="primary"
            @click="$router.push({ path: `dues/detail/${item.person}` })"
          >
            <v-icon small>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <Pagination
        :length="length"
        :total="total"
        :current-page="queryData.page"
        :limit="queryData.limit"
        :handler="paginateChange"
      />
    </v-card>
  </v-sheet>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment";
import Pagination from "@/components/Pagination";

import { getDuesTransactionList } from "@/api/admin/transactions";
import { priceFormat } from "@/utils/validate";

import { get_class_list } from "@/api/admin/schoolClass";

export default {
  metaInfo: {
    title: i18n.t("routes.dues"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  filters: {
    priceFormat: value => {
      if (!value) return "0";
      return priceFormat(value);
    }
  },
  components: { Pagination },
  data() {
    return {
      currentDate: moment().format("YYYY-MM-DD"),
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg"),
      dataFilter: { year: null, grade: null, class: null },
      activePicker: null,
      tglLahir: false,
      queryData: {
        page: 1,
        limit: 10,
        sort: "created_at",
        order: "DESC",
        search: "",
        status: 1,
        birth_date: null,
        scool_year_master: this.$store.getters.g_user.active_schoolyear.id
      },
      tableHeaders: [
        {
          text: i18n.t("dues.table.name"),
          align: "left",
          value: "name"
        },
        {
          text: i18n.t("dues.table.bill"),
          value: "total_nominal"
        },
        {
          text: i18n.t("dues.table.paid"),
          value: "total_paid"
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 80
        }
      ],
      total: 0,
      length: 0,
      loading: false,
      dataTable: [],
      options: {},
      duesDataDialog: false,
      formTransaction: false,
      formPersonTransaction: null,
      classList: [],
      loadingGetClass: false
    };
  },
  watch: {
    tglLahir(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    options: {
      handler(param) {
        if (param.sortBy.length != 0) {
          this.queryData.sort = param.sortBy.length > 0 ? param.sortBy[0] : "";
          this.getDuesListHandler();
        }
      }
    }
  },
  async mounted() {
    await this.getDuesListHandler();
    this.loadingGetClass = true;

    get_class_list({
      grade: [],
      school_year: [this.scool_year_master]
    })
      .then(res => {
        if (res.data.code) {
          this.classList = res.data.data;
        }
        this.loadingGetClass = false;
      })
      .catch(() => (this.loadingGetClass = false));
  },
  methods: {
    openPayForm(item) {
      this.formTransaction = true;
      this.formPersonTransaction = item.person;
    },
    setColor(param) {
      let color = "";
      switch (param) {
        case "NOT_YET_PAID":
          color = "error";
          break;
        case "PAID_OFF":
          color = "success";
          break;
        case "PARTIALLY_PAID":
          color = "warning";
          break;
        default:
          color = "grey";
          break;
      }
      return color;
    },
    getFilter(res) {
      switch (res.type) {
        case "school_year":
          this.filter.school_year_master = res.data.id;
          break;
        case "select_grade":
          this.filter.class_master = res.data;
          break;
        case "select_class":
          this.filter.classroom = res.data;
          break;
        default:
          break;
      }

      this.dataTable = [];
      this.total = 0;
      this.length = 0;

      if (this.filter.classroom && typeof this.filter.classroom == "number")
        this.getDuesListHandler();
    },
    getDuesListHandler() {
      this.formPersonTransaction = null;
      this.loading = true;

      getDuesTransactionList({ ...this.queryData })
        .then(res => {
          if (res.data.code) {
            const r = res.data.data;
            this.dataTable = r.data;
            this.total = r.total;
            this.length = r.last_page;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    actionHandler(param) {
      this.$router.push({
        name: param
      });
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getDuesListHandler();
    }
  }
};
</script>
